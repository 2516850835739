import React from 'react';

import BackArrow from '#/icons/back-arrow.svg';
import SelectArrow from '#/icons/select-arrow.svg';
import Facebook from '#/icons/facebook.svg';
import Google from '#/icons/google.svg';
import DropdownPlus from '#/icons/dropdown-plus.svg';
import DropdownClose from '#/icons/dropdown-close.svg';
import Close from '#/icons/close.svg';
import Perks1 from '#/icons/perks-1.svg';
import Perks2 from '#/icons/perks-2.svg';
import Perks3 from '#/icons/perks-3.svg';
import IconArrowSmallLeft from '#/icons/arrow-small-left.svg';
import IconFilters from '#/icons/filters.svg';
import IconArrowSmallRight from '#/icons/arrow-small-right.svg';
import IconSearch from '#/icons/search.svg';
import IconSearchFilterType from '#/icons/search-filter-type.svg';
import IconSearchFilterRadius from '#/icons/search-filter-radius.svg';
import IconSearchFilterPrice from '#/icons/search-filter-price.svg';
import IconSearchFilterBenefits from '#/icons/search-filter-benefits.svg';
import IconSearchFilterNetwork from '#/icons/search-filter-network.svg';
import IconSearchArea from '#/icons/search-area.svg';
import IconService from '#/icons/service.svg';
import IconUser from '#/icons/user.svg';
import IconSwitchToMap from '#/icons/switch-map.svg';
import IconStar from '#/icons/star.svg';
import IconLocation from '#/icons/location2.svg';
import IconBookmark from '#/icons/bookmark.svg';
import IconCalendar from '#/icons/calendar2.svg';
import IconArrowLeft from '#/icons/arrow-left.svg';
import IconArrowRight from '#/icons/arrow-right.svg';
import IconMarkerCover from '#/icons/marker-cover.svg';
import Minus from '#/icons/minus.svg';
import Filter from '#/icons/filter.svg';
import IconImages from '#/icons/images.svg';
import IconComments from '#/icons/comments.svg';
import IconAward from '#/icons/award.svg';
import IconRatingStar from '#/icons/rating-star.svg';
import Up from '#/icons/up.svg';
import Down from '#/icons/down.svg';
import Awards from '#/icons/awards.svg';
import Lock from '#/icons/lock.svg';
import Youtube from '#/icons/youtube.svg';
import Instagram from '#/icons/instagram.svg';
import Pinterest from '#/icons/pinterest.svg';
import Twitter from '#/icons/twitter.svg';
import GooglePlus from '#/icons/google_plus.svg';
import Linkedin from '#/icons/linkedin.svg';
import CurrentLocation from '#/icons/current-location.svg';
import Phone from '#/icons/phone.svg';
import Plus from '#/icons/plus.svg';

const icons = {
    'back-arrow': (props) => <BackArrow {...props} />,
    'phone': (props) => <Phone {...props} />,
    'select-arrow': (props) => <SelectArrow {...props} />,
    'facebook': (props) => <Facebook {...props} />,
    'google': (props) => <Google {...props} />,
    'dropdown-plus': (props) => <DropdownPlus {...props} />,
    'dropdown-close': (props) => <DropdownClose {...props} />,
    'close': (props) => <Close {...props} />,
    'perks-1': (props) => <Perks1 {...props} />,
    'perks-2': (props) => <Perks2 {...props} />,
    'perks-3': (props) => <Perks3 {...props} />,
    'arrow-small-left': (props) => <IconArrowSmallLeft {...props} />,
    'arrow-small-right': (props) => <IconArrowSmallRight {...props} />,
    'search': props => <IconSearch {...props} />,
    'search-filters': (props) => <IconFilters {...props} />,
    'search-filter-type': (props) => <IconSearchFilterType {...props} />,
    'search-filter-radius': (props) => <IconSearchFilterRadius {...props} />,
    'search-filter-price': (props) => <IconSearchFilterPrice {...props} />,
    'search-filter-benefits': (props) => <IconSearchFilterBenefits {...props} />,
    'search-filter-network': (props) => <IconSearchFilterNetwork {...props} />,
    'search-area': (props) => <IconSearchArea {...props} />,
    'service': (props) => <IconService {...props} />,
    'user': (props) => <IconUser {...props} />,
    'switch-map': (props) => <IconSwitchToMap {...props} />,
    'star': (props) => <IconStar {...props} />,
    'location': (props) => <IconLocation {...props} />,
    'bookmark': (props) => <IconBookmark {...props} />,
    'calendar': (props) => <IconCalendar {...props} />,
    'marker-cover': (props) => <IconMarkerCover {...props} />,
    'arrow-left': (props) => <IconArrowLeft {...props} />,
    'arrow-right': (props) => <IconArrowRight {...props} />,
    'minus': (props) => <Minus {...props} />,
    'filter': (props) => <Filter {...props} />,
    'images': (props) => <IconImages {...props} />,
    'comments': (props) => <IconComments {...props} />,
    'award': (props) => <IconAward {...props} />,
    'rating-star': (props) => <IconRatingStar {...props} />,
    'up': (props) => <Up {...props} />,
    'down': (props) => <Down {...props} />,
    'awards': (props) => <Awards {...props} />,
    'lock': props => <Lock {...props} />,
    'youtube': props => <Youtube {...props} />,
    'instagram': props => <Instagram {...props} />,
    'pinterest': props => <Pinterest {...props} />,
    'twitter': props => <Twitter {...props} />,
    'googleplus': props => <GooglePlus {...props} />,
    'linkedin': props => <Linkedin {...props} />,
    'current-location': props => <CurrentLocation {...props} />,
    'plus': props => <Plus {...props} />
};

export default icons;
