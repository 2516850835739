import './Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../../Icon/Icon';
import {classes} from "~/helpers/classes";

const {string, func, node, bool, oneOf} = PropTypes;

Button.propTypes = {
    children: node,
    type: oneOf(['primary', 'link', 'dot-link', 'line-link']),
    color: string,
    onClick: func,
    className: string,
    disabled: bool,
    href: string,
    htmlType: string,
    size: oneOf(['default', 'small']),
    isFluid: bool,
    isUppercase: bool
};

Button.defaultProps = {
    children: null,
    type: null,
    color: null,
    onClick: () => null,
    className: '',
    disabled: false,
    href: null,
    htmlType: 'button',
    size: 'default',
    isFluid: false,
    isUppercase: false
};

function Button(
    {
        children,
        isFluid,
        size = 'default',
        type,
        color,
        disabled,
        onClick,
        className = '',
        href,
        icon,
        htmlType,
        isUppercase,
        ...props
    }
) {
    const iconOnly = !!icon && !children;

    children = <span>{children}</span>;

    if (icon) {
        children = (
            <>
                <Icon type={icon}/>
                {children}
            </>
        );
    }

    const buttonClassNames = classes(
        "Button",
        className,
        type,
        color,
        `button_${size}`,
        ['button_uppercase', isUppercase],
        ['button_fluid',isFluid],
        ['with-icon', icon],
        ['icon-only', iconOnly]
    );

    return href ? (
        <a href={href} className={cn('Button', type, color, className)} onClick={onClick} {...props}>
            {children}
        </a>
    ) : (
        <button
            className={buttonClassNames}
            onClick={onClick}
            disabled={disabled}
            type={htmlType}
            {...props}
        >
            {children}
        </button>
    );
}

export default Button;
