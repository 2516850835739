import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import keys from 'lodash/keys';

import './Icon.module.scss';
import icons from './icons';

const {string, oneOf} = PropTypes;

function Icon(props) {
    const {type, className, ...otherProps} = props;

    if (!keys(icons).includes(type)) {
        return (
            <span>{`Icon.${type}`}</span>
        );
    }

    const oldIcon = icons[type]();
    const oldProps = oldIcon.props;
    const viewBox = oldProps.viewBox ? oldProps.viewBox : `0 0 ${oldProps.width} ${oldProps.height}`;
    const height = '1em';
    const width = viewBox.split(' ')[2] / viewBox.split(' ')[3] + 'em';
    const newProps = {viewBox, height, width};
    const icon = icons[type](newProps);

    return (
        <div
            className={cn('Icon', type, className)}
            {...otherProps}
        >
            {icon}
        </div>
    );
}

Icon.propTypes = {
    type: oneOf(keys(icons)).isRequired,
    className: string
};

Icon.defaultProps = {
    className: ''
};

export default Icon;
