import './ErrorPage.scss';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import querystring from 'querystring';
import cn from 'classnames';
import {setCountry, setLanguage} from '$/redux/locate/actions';
import getCookie from '$/helpers/getCookie';
import {URL_GET_CURRENT_LOCALES} from '$/api/routes';
import {ajax} from '$/api/ajax';
import Button from '~/components/buttons/Button/Button';
import Spinner from '~/components/Spinner/Spinner';
import background from '#/404.png';

class ErrorPage extends Component {
    state = {
        loaded: false
    };

    componentDidMount() {
        const {language, country, setLanguageDispatched, setCountryDispatched} = this.props;
        const cookieLanguage = getCookie('language') || getCookie('user-language');
        const cookieCountry = getCookie('country') || getCookie('user-country');

        if (cookieLanguage && cookieCountry) {
            if (!language) setLanguageDispatched(cookieLanguage);
            if (!country) {
                setCountryDispatched({
                    country: cookieCountry,
                    countryCode: cookieCountry.toUpperCase()
                });
            }
            this.setState({loaded: true});
        } else {
            let latitude;
            let longitude;

            navigator.geolocation.getCurrentPosition(
                ({coords: {latitude: lat, longitude: lng}}) => {
                    latitude = lat;
                    longitude = lng;
                },
                e => {
                    console.log('ERROR!!', e);
                }
            );

            const url = `${URL_GET_CURRENT_LOCALES}${
                latitude && longitude ? `?${querystring.encode({latitude, longitude})}` : ''
            }`;

            ajax({
                url,
                cb: ({data: {country_code: countryCode, locale}}) => {
                    if (!language) setLanguageDispatched(cookieLanguage || (locale === 'uk' ? 'ua' : locale));
                    if (!country) {
                        setCountryDispatched({
                            country: cookieCountry || countryCode.toLowerCase(),
                            countryCode
                        });
                    }
                    this.setState({loaded: true});
                },
                err: err => {
                    console.log('err', err);
                    setLanguageDispatched('ua');
                    setCountryDispatched({
                        country: 'ua',
                        countryCode: 'UA'
                    });
                    this.setState({loaded: true});
                }
            });
        }
    }

    render() {
        const {language, country, image, className, spinnerColor, translations} = this.props;
        const {loaded} = this.state;
        const translation = translations[language === 'ua' ? 'uk' : language] || {};

        return (
            <div className={cn('ErrorPage', className)}>
                <img className="image" src={image || background} alt="background"/>
                <div className="info">
                    <span className="code">404</span>
                    <span className="text">
            {translation['error_page: status_codes: not_found'] || 'Page not found'}
          </span>
                    {loaded ? (
                        <Button
                            className="link"
                            type="link"
                            href={`/${language === 'uk' ? 'ua' : language}-${country}`}
                        >
                            {translation['error_page: buttons: back'] || 'Back to home page'}
                        </Button>
                    ) : (
                        <Spinner color={spinnerColor}/>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    language: state.locate.language,
    country: state.locate.country,
    translations: state.locate.translations,
    user: state.auth.user
});

const dispatchedActions = {
    setLanguageDispatched: setLanguage,
    setCountryDispatched: setCountry
};

export default connect(
    mapStateToProps,
    dispatchedActions
)(ErrorPage);
