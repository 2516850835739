import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Spin from 'antd/lib/spin';

import './Spinner.module.scss';

const Spinner = ({color, size, isCentered}) => {
    return <div className={cn('Spinner', color, isCentered && 'Spinner__center')}>
        <Spin size={size}/>
    </div>
};

const {oneOf, bool} = PropTypes;

Spinner.propTypes = {
    color: oneOf(['violet', 'yellow', 'rose']),
    size: oneOf(['small', 'large']),
    isCentered: bool
};

Spinner.defaultProps = {
    color: null,
    size: null,
    isCentered: false
};

export default Spinner;
